import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import './App.css';
import HomePage from './components/HomePage'
import Membership from './views/Membership';
import Benefits from './views/Benefits';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
        <Route path={`/`} component={HomePage} exact/>
        <Route path={`/membership`} component={Membership} exact/>
        <Route path={`/benefits-resources`} component={Benefits} exact/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
