export const allwellHeader = {
    image: 'topLogo.svg'
}

export const infoData = {
    heading: "Well-Being Ecosystem",
    text: "Our main goal is to improve your well-being through education and support. MyAllWell was created to offer advice in all areas of life, including how to improve your finances and physical health, reach career goals, and thrive in relationships and your community."
}


export const navbarItems = [
    {
        "title": "Membership",
        "href": "/membership",
        "target":"",
    },
    {
        "title": "Benefits & Resources",
        "href": "/benefits-resources",
        "target":"",
    },
    {
        "title": "Blogs",
        "href": "https://blog.myallwell.org/",
        "target":"_blank",
    }

]

export const cardsData = [{
    text: "Alliance of Well-Being Associations (MyAllWell) is a non-profit on a mission to create a well-being economy. We are dedicated to providing information to improve everyday people's overall well-being—from your financial and physical well-being to being successful in your career, community and social relationships.",
    heading:"Our Mission"
},
{   image: "financial.png",
    text: "Financial well-being is achieved when you’re able to fully meet your financial obligations, can feel secure in your financial future and are able to make financial choices that allow you to enjoy your life.",
    heading:"financial"
},
{
    image: "physical.png",
    text: "Physical well-being includes lifestyle choices to ensure optimal health and to live in a balanced state of body, mind and spirit.",
    heading: "physical"
},
{   image: "career.png",
    text: "Career well-being includes how you feel about your job right now, your career trajectory and how your work is helping you get what you want out of life.",
    heading: "career"
},
{
    image: "social.png",
    text: "Social well-being is achieved when your basic human needs are met and you are able to coexist peacefully in your relationships.",
    heading:"social"
},
{   image:"community.png",
    text: "Community well-being includes a healthy state of social, economic, environmental and cultural conditions identified by individuals as essential for them to flourish.",
    heading:"community"
}
]


export const accordianLeftData =[{
    heading: "Guided Meditation",
    icon: "ArrowDown.svg",
    content: "Meditation is a practice where you use a mindfulness technique to train your attention and awareness.",
    title: "Well-Being Benefit",
    description: "The goal is to achieve a mentally clear and emotionally calm state that supports your mental and physical health.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'dash.svg',
        title: "community"
    }]
},
{
    heading: "Well-Being Videos",
    icon: "ArrowDown.svg",
    content: "Your ability to live a healthy and happy life depends on the knowledge you have to do just that.",
    title: "Well-Being Benefit",
    description: "Use the information you learn about in these quick videos to improve your overall well-being.",
    footerData: [{
        icon: "arrowUp.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'arrowUp.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "10 Tips Series",
    icon: "ArrowDown.svg",
    content: "See all the different ways to approach improving your finances, relationships and health.",
    title: "Well-Being Benefit",
    description: "Try out some of the best science-backed tips to make progress in these areas one by one.",
    footerData: [{
        icon: "arrowUp.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'arrowUp.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Gratitude Journal",
    icon: "ArrowDown.svg",
    content: "A gratitude journal is a tool to keep track of the good things that happen in your life.",
    title: "Well-Being Benefit",
    description: "Reap many of the great social, psychological and physical health benefits that come from being thankful.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Quizzes",
    icon: "ArrowDown.svg",
    content: "See what you already know about different well-being topics and take the opportunity to learn even more.",
    title: "Well-Being Benefit",
    description: "Give your brain a bit of a test drive in different areas while learning the real facts.",
    footerData: [{
        icon: "arrowUp.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'arrowUp.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Life Lessons Video",
    icon: "ArrowDown.svg",
    content: "Get inspired by everyday people who’ve accomplished amazing things in their lives.",
    title: "Well-Being Benefit",
    description: "Take the opportunity to dream big both personally and professionally while learning from those who came before you.",
    footerData: [{
        icon: "arrowUp.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'arrowUp.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Brain Workouts",
    icon: "ArrowDown.svg",
    content: "Brain teasers make you think in unconventional ways to come to the correct answer.",
    title: "Well-Being Benefit",
    description: "Practice lateral thinking, boost your overall brain activity and improve your memory.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'dash.svg',
        title: "community"
    }]
}]

export const accordianRightData=[
{
    heading: "Thoughtfulness",
    icon: "ArrowDown.svg",
    content: "Different belief systems and spiritual leaders have provided us with many different ways to approach the world and ourselves.",
    title: "Well-Being Benefit",
    description: "Spend some time considering what other spiritual beliefs have to offer us in our daily lives.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Global Climate Change",
    icon: "ArrowDown.svg",
    content: "Climate change is the change in our environment and weather patterns caused by the human emissions of greenhouse gases.",
    title: "Well-Being Benefit",
    description: "Learn about the role of trees in combating climate change and how they benefit the environment.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "dash.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Be Kind",
    icon: "ArrowDown.svg",
    content: "Spreading kindness is a lovely way to go through life, but it can also offer plenty of benefits for you as well.",
    title: "Well-Being Benefit",
    description: "Get ideas for kind acts toward strangers and loved ones alike.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'arrowUp.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
},
{
    heading: "Yoga Studio",
    icon: "ArrowDown.svg",
    content: "Yoga is a group of physical, mental and spiritual practices that originated in ancient India.",
    title: "Well-Being Benefit",
    description: "The benefits of yoga can be both physical as well as emotional, with different poses and flows targeting areas of the body.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'dash.svg',
        title: "community"
    }]
},
{
    heading: "Relaxation Music",
    icon: "ArrowDown.svg",
    content: "Soft and unobtrusive music can help you center your thoughts and unwind.",
    title: "Well-Being Benefit",
    description: "Ease your everyday stress and anxiety with calming and gentle music.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "arrowUp.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'dash.svg',
        title: "community"
    }]
},
{
    heading: "Disaster Preparedness",
    icon: "ArrowDown.svg",
    content: "When disaster strikes, you rarely have time to reach for your phone to look up the best place to stay in an earthquake or what to avoid after a flood.",
    title: "Well-Being Benefit",
    description: "Learn how to protect yourself and your family from disasters.",
    footerData: [{
        icon: "dash.svg",
        title: "financial"
    },
    {
        icon: "dash.svg",
        title: "physical"
    },
    {
        icon: 'dash.svg',
        title: "career"
    },
    {
        icon: 'dash.svg',
        title: "social"
    },
    {
        icon: 'arrowUp.svg',
        title: "community"
    }]
}

]

export const bottomCardsInfo = {
    heading: "Who We Partner With",
    text: "MyAllWell offers ways for individuals to learn more about improving all aspects of their overall well-being, including their personal finances, physical health, career success, community achievement and social relationships. We also partner with a variety of institutions to help spread well-being information and educational tools."
}

export const cardsBottomData = [
    {
        title: 'Financial Institution',
        content: "We work with financial institutions to provide additional resources to their members."
    },
    {
        title: 'Health & Wellness',
        content: "We offer ways for health and wellness initiatives to spread educational materials."
    },
    {
        title: 'Affinity Club',
        content: "We offer a great way for affinity clubs to motivate members to live healthier and happier lives."
    },
    {
        title: 'Employers',
        content: "We work with employers to provide well-being resources to their employees."
    }
]

export const footerContent = {
    addline1: 'Alliance of Well-Being Associations',
    addline2: '1200 Route 22 East, Suite 2000,',
    addline3: 'Bridgewater Township, NJ 08807',
    contactText: 'Contact: ',
    phone: '908 253 3599',
    emailText: 'Email: ',
    email: 'hello@myallwell.org',
    copyright: "MyAllWell © 2021. All Rights Reserved."
}

export const registrationLinks = {
    default: 'https://registration-dev.myallwell.org',
    production: 'https://registration.myallwell.org'
}
export const liteAppLinks = {
    default: 'https://app-preprod.myallwell.org',
    production: 'https://app.myallwell.org'
}